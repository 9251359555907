import { ErrorMessage, useField } from 'formik';
import InputDatePicker from '../InputDatePicker/InputDatePicker';
import { ChangeEvent } from 'react';

interface InputDatePickerFieldProps {
  disabled?: boolean;
  label?: string;
  name: string;
  toDate?: Date;
  onInputClick?: () => void;
}

export const InputDatePickerField = (props: InputDatePickerFieldProps) => {
  const { name, ...restProps } = props;

  const [
    { value: fieldValue, onBlur: fieldOnBlur, onChange: fieldOnChange },
    meta,
  ] = useField(name);

  const createEvent = (date: Date | null) => {
    const event = new Event('change');
    Object.defineProperties(event, {
      target: {
        value: {
          value: date,
          name: name,
        },
        writable: true,
      },
    });
    return event;
  };

  const handleOnChange = (value: Date | null, event?: ChangeEvent) => {
    // formik wants us to pass its onChange event to the input
    // but it takes the event.target.value to save into its state
    // which may be partially typed in value
    // since formik will soon go, thats a temporary solution
    // will remove when LabRequisitionForm, PrescriptionForm, ConsultForm move to react-hook-form
    if (event) {
      const { type, name, id } = event.target as HTMLInputElement;
      fieldOnChange({
        ...event,
        target: {
          type,
          name,
          id,
          // need to pass an empty string here instead of null
          // because otherwise yup returns a wrong error message
          value: value || '',
        },
      });
    } else {
      // for when date is picked in Calendar Picker, it doesn't return the event, just the value
      // formik wants either an event or string, thats why putting the Date value in the event wrapper
      fieldOnChange(createEvent(value));
    }
  };

  return (
    <div className="space-y-2">
      <InputDatePicker
        selected={fieldValue || null}
        name={name}
        error={meta.error}
        placeholder="mm/dd/yyyy"
        onBlur={fieldOnBlur}
        onChange={handleOnChange}
        {...restProps}
      />
      <ErrorMessage
        name={name}
        component="p"
        className="text-red-500 text-sm"
      />
    </div>
  );
};

export default InputDatePickerField;
