import { FunctionComponent, PropsWithChildren } from 'react';

export const CenteredLayout: FunctionComponent<PropsWithChildren<unknown>> = ({
  children,
}) => {
  return (
    <div className="flex min-h-screen">
      <div className="mx-auto my-auto">
        <div className="max-w-512 sm:min-w-512 border-grey-200 mx-4 rounded-lg border bg-white p-6 shadow-md sm:p-10">
          {children}
        </div>
      </div>
    </div>
  );
};
