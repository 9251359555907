import { FunctionComponent, PropsWithChildren, useId } from 'react';
import { Field } from 'formik';
import clsx from 'clsx';
import { checkboxStyles } from '../forms/shared-input-styles';

type Props = {
  name: string;
  className?: string;
  disabled?: boolean;
};

export const Checkbox: FunctionComponent<PropsWithChildren<Props>> = ({
  children,
  disabled,
  name,
  className,
}) => {
  const id = useId();

  return (
    <div className="flex space-x-4">
      <Field
        id={id}
        name={name}
        disabled={disabled}
        type="checkbox"
        className={clsx(checkboxStyles, className)}
      />
      <label htmlFor={id} className="text-grey-500 text-xs leading-5">
        {children}
      </label>
    </div>
  );
};
