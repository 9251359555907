import { To, NavLink } from 'react-router-dom';

type PatientTabLinkProps = {
  children: React.ReactNode;
  to: To;
};

export const PatientTabLink = ({ to, children }: PatientTabLinkProps) => (
  <NavLink
    to={to}
    className="hover:border-grey-300 hover:text-grey-700 aria-[current]:border-brand-primary-500 aria-[current]:text-brand-primary-500 text-grey-500 whitespace-nowrap border-b-2 border-transparent px-5 py-4 font-medium transition-colors focus:outline-none aria-[current]:font-bold"
  >
    {children}
  </NavLink>
);
