import { StripeVerificationSessionStatusEnum } from '@pm/graphql';
import { FC } from 'react';
import { format, parseISO } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleCheck,
  faCircleExclamation,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

type Props = {
  status: StripeVerificationSessionStatusEnum | null | undefined;
  dateVerified?: string | null | undefined;
  testId?: string;
};

type IconProps = {
  icon: IconDefinition;
  fill: string;
};

type VerificationStatusProps = {
  label: string;
  icon?: IconProps;
};

export const IdentityVerification: FC<Props> = ({
  status,
  dateVerified,
  testId,
}) => {
  // can be found in each app's /public/locales/
  const { t } = useTranslation('identification');

  const Scenarios: Record<string, VerificationStatusProps> = {
    NotVerified: {
      label: t('NotVerified'),
      icon: { icon: faCircleExclamation, fill: 'text-risk-red' },
    },
    Processing: {
      label: t('InProgress'),
    },
    Verified: {
      label: t('Verified2'),
      icon: { icon: faCircleCheck, fill: 'text-brand-primary-500' },
    },
  };

  const StatusScenarios: Map<
    StripeVerificationSessionStatusEnum,
    VerificationStatusProps
  > = new Map([
    [StripeVerificationSessionStatusEnum.Canceled, Scenarios.NotVerified],
    [StripeVerificationSessionStatusEnum.Processing, Scenarios.Processing],
    [StripeVerificationSessionStatusEnum.RequiresInput, Scenarios.NotVerified],
    [StripeVerificationSessionStatusEnum.Verified, Scenarios.Verified],
  ]);

  const getStatusScenario = (
    status: StripeVerificationSessionStatusEnum | null | undefined,
  ) => {
    if (status == null) {
      return Scenarios.NotVerified;
    }

    return StatusScenarios.get(status);
  };

  const statusScenario = getStatusScenario(status);

  if (!statusScenario) {
    return null;
  }

  const { label, icon } = statusScenario;

  return (
    <div className="text-grey-700 flex items-center gap-1" data-testid={testId}>
      <div data-testid="identity-label">{label}</div>
      {dateVerified && format(parseISO(dateVerified), 'yyyy-MM-dd')}
      {icon && (
        <FontAwesomeIcon
          icon={icon.icon}
          className={`${icon.fill} ml-1 cursor-pointer`}
          title={label}
        />
      )}
    </div>
  );
};
