import { DosingRegimenEnum, PrescriptionFragment } from '@pm/graphql';
import { addDays, compareDesc, differenceInDays, parseISO } from 'date-fns';
import { BrandId } from '../types';

export type PrescriptionOption = {
  value: string;
  length: number;
};

type RemainingPills = {
  numPillsRemaining: number;
  isEstimate: boolean;
};

const defualtPrepPrescriptionOptions = [
  { value: 'Initial 30', length: 30 },
  { value: 'Initial 60', length: 60 },
  { value: 'Initial 90', length: 90 },
  { value: 'Refill 30', length: 30 },
  { value: 'Refill 60', length: 60 },
  { value: 'Refill 90', length: 90 },
];

/**
 * Gets available PrEP Rx prescription options
 *
 * @returns {PrescriptionOption[]} list of available PrEP prescription options
 */
export const getPrepPrescriptionOptions = (): PrescriptionOption[] => {
  return defualtPrepPrescriptionOptions;
};

/**
 * Gets duration of prescription type
 *
 * @param {PrescriptionOption[]} prescriptionOptions available prescription options
 * @param {string} typeValue type of prescription (i.e., 'Initial 30', 'Refill 60')
 * @returns {number} length of the given prescription in days (i.e., 30, 60, 90)
 */
export const prepPrescriptionLength = (
  prescriptionOptions: PrescriptionOption[],
  typeValue: string,
) => {
  return prescriptionOptions.find((obj) => {
    return obj.value === typeValue;
  })?.length;
};

/**
 * Gets number of pills remaining for a given prescription. If the due at date is provided,
 * the number of pills is the difference in days between today and the due date; otherwise,
 * the due date is estimated based on the prescription creation date and the prescription length.
 *
 * @param {PrescriptionFragment} prescription prescription to get the remaining number of pills for
 * @returns {RemainingPills} number of pills left in prescription, and whether this number is an estimated value
 */
export const getNumPillsRemainingInPrescription = (
  prescription: PrescriptionFragment,
): RemainingPills => {
  const isEstimate = prescription.dueAt == null;
  const endDate = prescription.dueAt
    ? parseISO(prescription.dueAt)
    : addDays(
        parseISO(prescription.createdAt),
        prescription.prescriptionLength ?? 0,
      );

  const numDaysUntilDue = differenceInDays(endDate, new Date());
  const numPillsRemaining = numDaysUntilDue > 0 ? numDaysUntilDue : 0;

  return {
    numPillsRemaining,
    isEstimate,
  };
};

/**
 * Gets number of days until a prescription's due date. Returns a negative value if in the past.
 * Will use the prescription's `dueAt` date if available, otherwise will use the `createdAt` date.
 */
export const numberOfDaysUntilPrescriptionEnd = (
  prescription: PrescriptionFragment,
) => {
  const endDate = prescription.dueAt
    ? parseISO(prescription.dueAt)
    : addDays(
        parseISO(prescription.createdAt),
        prescription.prescriptionLength ?? 0,
      );

  const numDaysUntilDue = differenceInDays(endDate, new Date());

  return numDaysUntilDue;
};

/**
 * Gets the latest prescription, based on treatment start date.
 *
 * @param {PrescriptionFragment[]} prescriptions prescriptions to compare for the most recent
 * @returns {PrescriptionFragment} latest prescription
 */
export const getLatestPrescription = (
  prescriptions: PrescriptionFragment[],
): PrescriptionFragment | undefined => {
  const mostRecentToLeastRecentPrescriptions = prescriptions.sort(
    (prescription1, prescription2) => {
      return compareDesc(
        parseISO(prescription1.treatmentStartAt || ''),
        parseISO(prescription2.treatmentStartAt || ''),
      );
    },
  );

  return mostRecentToLeastRecentPrescriptions[0];
};

/**
 * Gets list of prescriptions matching a specific brand
 *
 * @param {PrescriptionFragment[]} prescriptions prescriptions to filter from
 * @param {BrandId} brandId the brand ID to filter prescriptions with
 * @returns {PrescriptionFragment[]} prescriptions only containing the prescription matching the provided brand
 */
export const filterPrescriptionsByBrand = (
  prescriptions: PrescriptionFragment[],
  brandId: BrandId,
): PrescriptionFragment[] => {
  return prescriptions.filter(
    (prescription) => prescription.brand?.id === brandId,
  );
};

/**
 * Gets the latest Freddie PrEP prescription from a list of prescriptions
 *
 * @param {PrescriptionFragment[]} prescriptions prescriptions to check against
 * @returns {PrescriptionFragment} latest Freddie PrEP prescription
 */
export const getLatestPrepPrescription = (
  prescriptions: PrescriptionFragment[],
): PrescriptionFragment | undefined => {
  const prepPrescriptions = prescriptions.filter(
    (prescription) => prescription.prepType && prescription.prepType !== 'n/a',
  );
  const latestPrepPrescription = prepPrescriptions[0];

  return latestPrepPrescription;
};

/**
  @returns {string[]} an array of fulfillment methods
*/
export function getFulfillmentMethods() {
  return [
    'Affirming Care',
    'Gilead Pharmacy (PAP Program)',
    'SRx Saskatoon Pharmacy',
    'SRx Winnipeg Pharmacy',
    'Exchange District Pharmacy',
    'Saskatoon Family Pharmacy',
    'Mint Edmonton',
    'Non-Partner Pharmacy',
  ];
}

/**
  @returns {object[]} an array of PrEP dosing regimen options
*/
export function getDosingRegimenOptions() {
  return [
    {
      label: 'Daily use',
      value: DosingRegimenEnum.Daily,
    },
    {
      label: 'On-demand',
      value: DosingRegimenEnum.OnDemand,
    },
  ];
}
