import clsx from 'clsx';
import { AppointmentBar } from './AppointmentBar';
import { Sidebar } from './Sidebar';
import { Topbar } from './Topbar';
import { ReactNode } from 'react';

type Props = {
  title?: string;
  noPadding?: boolean;
  wide?: boolean;
  narrow?: boolean;
  sidebarContent: ReactNode;
  topbarLogo: JSX.Element;
  rightDrawerSlot?: React.ReactNode;
  appointmentInfo?: JSX.Element;
  showAppointmentBar?: boolean;
  children: React.ReactNode;
};

const Title = ({ children }: { children?: React.ReactNode }) => {
  if (!children) {
    return null;
  }

  return <h1 className="text-grey-400 mb-2 text-base font-bold">{children}</h1>;
};

type PageContentProps = {
  title?: string;
  children: React.ReactNode;
  noPadding?: boolean;
  wide?: boolean;
  narrow?: boolean;
};

export const PageContent = ({
  title,
  children,
  noPadding,
  wide,
  narrow,
}: PageContentProps) => (
  <div className={clsx('min-w-0 flex-1')}>
    <div
      className={clsx(
        'mx-auto min-h-full',
        narrow && 'max-w-lg',
        wide && 'w-full',
        !narrow && !wide && 'max-w-7xl',
        !noPadding && 'px-4 sm:px-6 md:px-8',
        !noPadding && 'py-3 md:py-6',
      )}
    >
      <Title>{title}</Title>
      {children}
    </div>
  </div>
);

export const MainAppLayout = ({
  sidebarContent,
  topbarLogo,
  wide,
  narrow,
  title,
  rightDrawerSlot,
  appointmentInfo,
  showAppointmentBar = false,
  noPadding = false,
  children,
}: Props) => {
  const appointmentBarOpen =
    appointmentInfo !== undefined && showAppointmentBar;

  const hasRightDrawer = Boolean(rightDrawerSlot);

  return (
    <div className="flex flex-col min-h-full w-full overflow-hidden overscroll-none fixed inset-0">
      <Topbar showMenuControl={!!sidebarContent}>{topbarLogo}</Topbar>
      <div
        className={clsx(
          'flex flex-1 overflow-y-hidden',
          (hasRightDrawer || appointmentBarOpen) && 'overflow-hidden',
        )}
      >
        {sidebarContent && <Sidebar>{sidebarContent}</Sidebar>}
        <div className="flex w-full flex-1 flex-col overflow-hidden">
          {appointmentBarOpen && (
            <AppointmentBar>{appointmentInfo}</AppointmentBar>
          )}
          <main className="flex flex-1 justify-between overflow-y-auto">
            <PageContent
              title={title}
              noPadding={noPadding}
              wide={wide}
              narrow={narrow}
            >
              {children}
            </PageContent>
          </main>
        </div>
        {rightDrawerSlot}
      </div>
    </div>
  );
};
