import clsx from 'clsx';

type BadgeType =
  | 'freddie'
  | 'frida'
  | 'info'
  | 'success'
  | 'danger'
  | 'warning'
  | 'disabled';

type BadgeProps = {
  text: string;
  textVariant?: 'uppercase' | 'capitalized';
  type?: BadgeType;
};

const Badge = ({ text, textVariant, type }: BadgeProps) => {
  return (
    <span
      className={clsx('rounded-md px-2 py-1 text-xs font-medium', {
        uppercase: textVariant === 'uppercase',
        capitalize: textVariant === 'capitalized',
        'bg-grey-200 text-grey-700': !type,
        'bg-freddie-green-2 text-content-positive': type === 'freddie',
        'bg-frida-green text-white': type === 'frida',
        'bg-blue-500 text-white': type === 'info',
        'bg-green-500 text-white': type === 'success',
        'bg-red-500 text-white': type === 'danger',
        'bg-yellow-200 text-black': type === 'warning',
        'bg-grey-300 text-grey-600': type === 'disabled',
      })}
    >
      {text}
    </span>
  );
};

export default Badge;
