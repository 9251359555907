import { cva, type VariantProps } from 'class-variance-authority';
import { type ReactNode } from 'react';
import { Icon } from '../Icon/Icon';
import { IconButton } from '../IconButton/IconButton';
import { Text } from '../Text/Text';

const sidePanel = cva(
  'flex flex-col border-l justify-between h-[100%] divide-gray-200 divide-y overflow-hidden',
);

export type SidePanelProps = VariantProps<typeof sidePanel> & {
  title: string;
  children: ReactNode;
  onClose: () => void;
};

/**
 * Controlled component with optional CTAs at the bottom intended to complement primary content.
 * Use the adjacent SidePanelCTA component to render CTAs.
 *
 * @variation desktop - Content pushed to the left; remains visible while users interact with primary content
 * @variation mobile - Behaves like a drawer; users unable to interact with primary content
 *
 * @example
 * <SidePanel
 *   title="Some title"
 *   onClose={() => setOpen(false)}
 * >
 *   <SidePanelContent>{/* ... /*}</SidePanelContent>
 *   <SidePanelCTA>{/* ... /*}</SidePanelCTA>
 * </SidePanel>
 */
export const Root = ({ title, children, onClose }: SidePanelProps) => {
  return (
    <div className={sidePanel()}>
      <div className="flex flex-row items-center p-xl">
        <Text as="h1" size="headingS">
          {title}
        </Text>
        <div className="ml-auto">
          <IconButton
            intent="ghost"
            size="small"
            aria-label="Dismiss panel"
            onClick={onClose}
          >
            <Icon name="close" size="large" />
          </IconButton>
        </div>
      </div>
      {children}
    </div>
  );
};
