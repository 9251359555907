import { ChangeEvent, FunctionComponent } from 'react';
import { ErrorMessage, useField, useFormikContext } from 'formik';
import clsx from 'clsx';
import './flowbiteField.css';
import { CharacterCount } from '../CharacterCount';
import { StyledInput } from '../forms/StyledInput';

export type FlowbiteFieldProps = React.HTMLProps<HTMLInputElement> & {
  name: string;
  dataCy?: string;
  bottomMargin?: boolean;
  subtext?: string;
  formatInput?: (value: string) => string;
  formatOutput?: (value: string) => string;
  required?: boolean;
  characterCount?: number;
};

export const FlowbiteField: FunctionComponent<FlowbiteFieldProps> = ({
  name,
  label,
  placeholder,
  className,
  autoComplete = 'on',
  onChange,
  formatInput = (value) => value,
  formatOutput = (value) => value,
  dataCy,
  bottomMargin = true,
  type = 'text',
  subtext,
  maxLength,
  required = false,
  characterCount,
  autoCapitalize,
  spellCheck,
}) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);
  const value = formatInput(field.value);

  return (
    <div>
      <label
        htmlFor={name}
        className={clsx('text-grey-500 text-sm font-medium', {
          "after:ml-1 after:text-red-500 after:content-['*']": required,
        })}
      >
        {label}
      </label>
      <StyledInput
        {...field}
        type={type}
        placeholder={placeholder}
        autoComplete={autoComplete}
        autoCapitalize={autoCapitalize}
        spellCheck={spellCheck}
        data-cy={dataCy}
        aria-label={name}
        value={value}
        aria-invalid={meta.error && meta.touched ? true : undefined}
        className={className}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          const updatedValue = formatOutput(event.target.value);
          setFieldValue(name, updatedValue, true);
          onChange && onChange(event);
        }}
        maxLength={maxLength}
      />
      {subtext && (
        <div className="text-grey-500 pl-1 text-xs font-medium">{subtext}</div>
      )}
      <div
        className={clsx(
          // TODO: Remove margin in favour of consistent spacing
          'mt-1 flex items-center justify-between',
          bottomMargin && 'mb-4',
        )}
      >
        <ErrorMessage
          name={field.name}
          className={clsx(
            'text-critical-dark mt-1 border-none text-sm font-medium',
            bottomMargin && 'mb-4',
          )}
          component="p"
        />
        {characterCount && (
          <div className="ml-auto">
            <CharacterCount count={value?.length ?? 0} limit={characterCount} />
          </div>
        )}
      </div>
      {bottomMargin && <div className="mb-4"></div>}
    </div>
  );
};
