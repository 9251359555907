import clsx from 'clsx';

type Props = {
  children: React.ReactNode;
};

export const AppointmentBar = ({ children }: Props) => {
  return (
    <div
      id="appointmentBar"
      className={clsx(
        'px-4 py-3',
        'flex items-center justify-between',
        'bg-menu-background border-grey-200 border-b',
        'shadow-md drop-shadow',
      )}
    >
      <div className="flex h-fit flex-1 place-content-center">{children}</div>
    </div>
  );
};
