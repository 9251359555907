/// <reference types="tsconfig/segment" />
import { JSONValue } from './types';
/**
 * Used for updating the braze profile of a user
 */

export function analyticsIdentifyEvent(
  userId: string,
  traits?: Record<string, JSONValue>,
) {
  if (!window.analytics) {
    return;
  }
  window.analytics.identify(
    userId,
    {
      ...traits,
    },
    {
      integrations: {
        braze: true,
      },
    },
  );
}
