import { FunctionComponent, PropsWithChildren } from 'react';
import clsx from 'clsx';

type Props = {
  text: string;
  className?: string;
};

export const ReadonlyText: FunctionComponent<PropsWithChildren<Props>> = ({
  text,
  className,
}) => (
  <input
    readOnly
    type="text"
    value={text}
    className={clsx(
      'text-grey-700 mb-2 mt-0 w-full border-none pl-0 pt-0',
      className,
    )}
  />
);
