import { Bars3Icon, XMarkIcon } from '@heroicons/react/20/solid';
import { useMainAppLayout } from '../../providers/MainAppLayoutProvider';

type Props = {
  children: React.ReactNode;
  showMenuControl?: boolean;
};
export const Topbar = ({ children, showMenuControl = true }: Props) => {
  const { sidebarOpen, setSidebarOpen } = useMainAppLayout();

  return (
    <div
      id="topbar"
      className="bg-menu-background border-grey-200 flex h-16 items-center justify-between border-b py-3"
    >
      {showMenuControl && (
        <div className="flex items-center justify-center text-center">
          <button
            type="button"
            onClick={() => setSidebarOpen(!sidebarOpen)}
            aria-label="Toggle sidebar"
            className="text-grey-700 ml-4 inline-block h-8 w-8 opacity-100 transition-opacity duration-700 ease-out active:opacity-0"
          >
            {sidebarOpen ? <XMarkIcon /> : <Bars3Icon />}
          </button>
        </div>
      )}
      <div className="flex h-7 flex-1 place-content-center">{children}</div>
    </div>
  );
};
