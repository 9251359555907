import clsx from 'clsx';
import * as React from 'react';
import { Button } from '../Button';
import { XMarkIcon } from '@heroicons/react/20/solid';

type UnstyledableDiv = Omit<React.ComponentPropsWithoutRef<'div'>, 'className'>;

/* -------------------------------------------------------------------------------------------------
 * Panel
 * -----------------------------------------------------------------------------------------------*/

type PanelElement = React.ElementRef<'div'>;
type PanelProps = UnstyledableDiv;

const Panel = React.forwardRef<PanelElement, PanelProps>(
  (props, forwardedRef) => {
    return (
      <div
        {...props}
        className="w-100 divide-grey-200 z-10 flex h-full flex-col justify-between divide-y overflow-hidden bg-white motion-reduce:transition-none"
        ref={forwardedRef}
      />
    );
  },
);

Panel.displayName = 'Panel';

/* -------------------------------------------------------------------------------------------------
 * PanelHeading
 * -----------------------------------------------------------------------------------------------*/

type PanelHeadingElement = React.ElementRef<'div'>;
interface PanelHeadingProps extends UnstyledableDiv {
  onDismiss: () => void;
}

const PanelHeading = React.forwardRef<PanelHeadingElement, PanelHeadingProps>(
  ({ children, onDismiss, ...props }, forwardedRef) => {
    return (
      <PanelContent {...props} ref={forwardedRef}>
        <div
          className={clsx('flex items-center', {
            'justify-between': children,
            'justify-end': !children,
          })}
        >
          {children && <h2 className="text-lg">{children}</h2>}
          <div className="ml-3 flex h-7 items-center">
            <Button
              variant="tertiary"
              size="small"
              onClick={onDismiss}
              aria-label="Close panel"
            >
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </Button>
          </div>
        </div>
      </PanelContent>
    );
  },
);

PanelHeading.displayName = 'PanelHeading';

/* -------------------------------------------------------------------------------------------------
 * PanelContent
 * -----------------------------------------------------------------------------------------------*/

type PanelContentElement = React.ElementRef<'div'>;
interface PanelContentProps extends UnstyledableDiv {
  shouldFillContainer?: boolean;
  footer?: boolean;
}

const PanelContent = React.forwardRef<PanelContentElement, PanelContentProps>(
  ({ shouldFillContainer, footer, ...props }, forwardedRef) => {
    return (
      <div
        {...props}
        className={clsx(
          'w-full px-4 py-6 sm:px-6',
          shouldFillContainer && 'flex-1 overflow-y-auto',
          footer && 'rounded-t-2xl',
        )}
        ref={forwardedRef}
      />
    );
  },
);

PanelContent.displayName = 'PanelContent';

/* -------------------------------------------------------------------------------------------------
 * PanelFooter
 * -----------------------------------------------------------------------------------------------*/

type PanelFooterElement = React.ElementRef<'div'>;
type PanelFooterProps = UnstyledableDiv;

const PanelFooter = React.forwardRef<PanelFooterElement, PanelFooterProps>(
  (props, forwardedRef) => {
    return (
      <div
        {...props}
        className="w-full rounded-t-2xl px-4 py-6 sm:px-6"
        ref={forwardedRef}
      />
    );
  },
);

PanelFooter.displayName = 'PanelFooter';

const Root = Panel;
const Heading = PanelHeading;
const Content = PanelContent;
const Footer = PanelFooter;

export {
  Root,
  Heading,
  Content,
  Footer,
  Panel,
  PanelHeading,
  PanelContent,
  PanelFooter,
};
