import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Input, Modal, TextareaField } from '@pm/ui';
import { FormField, FormError, FormSubmitButton } from '@pm/forms';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useMessage } from '../../hooks/use-message/use-message';
import { toServerError } from '../../utilities/errors';

type EditMessageProps = {
  messageId: string;
  content?: string;
  open: boolean;
  onToggle: () => void;
};

export const EditMessage = ({
  messageId,
  content,
  open,
  onToggle,
}: EditMessageProps) => {
  const { edit } = useMessage({ messageId });

  const schema = Yup.object({
    content: Yup.string().required('Message is required'),
    reason: Yup.string().required('Reason is required'),
  });

  const methods = useForm({
    mode: 'all',
    values: { content: content ?? '', reason: '' },
    resolver: yupResolver(schema),
  });

  const { reset, setError, handleSubmit } = methods;

  const handleToggle = () => {
    reset();
    onToggle();
  };

  return (
    <Modal.Root open={open} onOpenChange={handleToggle}>
      <Modal.Content title="Edit message">
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(
              async ({ content, reason }) =>
                await edit({
                  content,
                  reason,
                  onSuccess: onToggle,
                  onError: () =>
                    setError(...toServerError('Message not updated')),
                }),
            )}
          >
            <Modal.Body>
              <div className="space-y-l">
                <FormError
                  error={{
                    title: 'Message not updated',
                    instructions:
                      'Please refresh the page and try again. If the issue persists, contact support.',
                  }}
                />
                <FormField
                  name="content"
                  label="Message"
                  Component={TextareaField}
                />
                <FormField
                  name="reason"
                  label="Reason for editing"
                  placeholder="Enter a reason for editing this message"
                  Component={Input}
                />
              </div>
            </Modal.Body>
            <Modal.Actions>
              <Button intent="secondary" width="full" onClick={handleToggle}>
                Cancel
              </Button>
              <FormSubmitButton width="full">Save</FormSubmitButton>
            </Modal.Actions>
          </form>
        </FormProvider>
      </Modal.Content>
    </Modal.Root>
  );
};
