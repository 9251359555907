import { PatientViewAssessmentFragment } from '@pm/graphql';

export const diagnosisOnlyStatusFromAssessment = (
  assessments: PatientViewAssessmentFragment[] | [],
) => {
  const screeningStep = assessments.find(
    (result) => result.type === 'Assessments::Frida::ScreeningAssessment',
  );
  const diagnosisOnlyStatus = screeningStep?.data?.diagnosisOnly ?? false;
  return diagnosisOnlyStatus;
};

export const getScreenOutStatusFromAssessment = (
  assessments: PatientViewAssessmentFragment[] | [],
) => {
  const screeningStep = assessments.find(
    (result) => result.type === 'Assessments::Frida::ScreeningAssessment',
  );
  const screenOutStatus = screeningStep?.data?.screenedOut ?? false;
  return screenOutStatus;
};
