import { type ReactNode } from 'react';
import { type VariantProps, cva } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';

const sidePanelContent = cva(
  'flex w-full text-body-l flex-1 overflow-y-auto p-xl',
);

type SidePanelContentProps = VariantProps<typeof sidePanelContent> & {
  children: ReactNode;
};

/**
 * Wrapper for the content section of SidePanel.
 */
export const Content = ({ children }: SidePanelContentProps) => {
  return <div className={twMerge(sidePanelContent())}>{children}</div>;
};
