import { useState, Fragment, useId } from 'react';
import clsx from 'clsx';
import { Combobox, Transition } from '@headlessui/react';
import { ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { useController } from 'react-hook-form';
import { textInputStyles } from './forms/shared-input-styles';

export type SearchSelectProps = {
  name: string;
  options: string[];
  label?: string;
  placeholder?: string;
  className?: string;
  icon?: boolean;
  disabled?: boolean;
};

/**
 * A searchable input with a dropdown menu that filters as you type.
 * Allows for free text to be used as well as selected options
 * from the dropdown menu.
 *
 * Note: cannot use the common component HookFormFlowbiteField here, as it interferes with
 * Headless UI's Combobox.Input component.
 */
export const SearchSelect = ({
  name,
  options,
  label,
  icon = true,
  placeholder,
  className,
  disabled = false,
}: SearchSelectProps) => {
  const textInputId = useId();
  const { field, fieldState } = useController({ name });
  const { error } = fieldState;
  const [query, setQuery] = useState(field.value ?? '');

  const filteredOptions =
    query === ''
      ? options
      : options.filter((option) =>
          option.toLowerCase().includes(query.toLowerCase()),
        );

  return (
    <div className="space-y-2">
      <Combobox {...field} as="div" disabled={disabled}>
        <div className="relative w-full space-y-2">
          {label && (
            <label
              htmlFor={textInputId}
              className="text-grey-500 text-sm font-medium"
            >
              {label}
            </label>
          )}

          <div className="relative w-full rounded-lg">
            <Combobox.Input
              id={textInputId}
              onChange={(event) => {
                setQuery(event.target.value);
                field.onChange(event.target.value);
              }}
              placeholder={placeholder}
              className={clsx(textInputStyles, className)}
              aria-invalid={error ? true : undefined}
              value={field.value}
            />
            {icon && (
              <Combobox.Button className="absolute inset-y-0 right-0 mr-2 flex items-center">
                <ChevronUpDownIcon
                  className="text-grey-500 h-5 w-5"
                  aria-hidden="true"
                />
              </Combobox.Button>
            )}
          </div>

          {filteredOptions.length > 0 && (
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100 motion-reduce:transition-none"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              afterLeave={() => setQuery('')}
            >
              <Combobox.Options
                className="absolute mt-1 max-h-60 w-full overflow-auto rounded-sm bg-white py-1 shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                // tailwind 'z-1' class doesn't work here
                style={{ zIndex: '1' }}
              >
                {filteredOptions.map((value) => (
                  <Combobox.Option
                    key={value}
                    value={value}
                    className={({ active }) =>
                      clsx(
                        'relative cursor-pointer select-none py-2 pl-10 pr-4',
                        active ? 'bg-grey-200' : 'bg-white',
                      )
                    }
                  >
                    {({ selected }) => (
                      <span
                        className={clsx(
                          'block truncate',
                          selected ? 'font-medium' : 'font-normal',
                        )}
                      >
                        {value}
                      </span>
                    )}
                  </Combobox.Option>
                ))}
              </Combobox.Options>
            </Transition>
          )}
        </div>
      </Combobox>
      {error && (
        <p className="text-critical-dark border-none text-sm font-medium">
          {error.message}
        </p>
      )}
    </div>
  );
};
