import clsx from 'clsx';
import { ErrorMessage, Field, useField } from 'formik';
import { FunctionComponent, PropsWithChildren, useId } from 'react';

import { selectStyles } from '../forms/shared-input-styles';
import BoolDropdown from './BoolDropdown';

export interface WrapperProps {
  name: string;
  label?: string;
  id?: string;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  required?: boolean;
}

const Wrapper: FunctionComponent<PropsWithChildren<WrapperProps>> = ({
  name,
  label,
  id: idProp,
  placeholder,
  className,
  children,
  disabled = false,
  required = false,
}) => {
  const _id = useId();
  const id = idProp || _id;
  const [field, meta] = useField(name);

  return (
    <div>
      <label
        htmlFor={id}
        className={clsx('text-grey-500 text-sm font-medium', {
          "after:ml-1 after:text-red-500 after:content-['*']": required,
        })}
      >
        {label}
      </label>
      <Field
        name={name}
        id={id}
        as="select"
        aria-invalid={meta.error && meta.touched ? true : undefined}
        className={clsx(selectStyles, label ? 'mt-0' : 'mt-3', className)}
        disabled={disabled}
        required={required}
      >
        {placeholder && (
          <option disabled value="">
            {placeholder}
          </option>
        )}
        {children}
      </Field>
      <ErrorMessage
        name={field.name}
        className={clsx(
          'text-critical-dark mb-3 mt-1 border-none text-sm font-medium',
        )}
        component="p"
      />
    </div>
  );
};

interface DropdownProps extends WrapperProps {
  options: object;
  required?: boolean;
}

export const Dropdown: FunctionComponent<DropdownProps> = (props) => {
  return (
    <Wrapper {...props}>
      {Object.entries(props.options).map(
        ([key, value]: [string, string], index: number) => (
          <option key={index} value={key}>
            {value}
          </option>
        ),
      )}
    </Wrapper>
  );
};

export { Wrapper, BoolDropdown };
