import { TableCell } from '../Table/TableCell';

type TableLoaderProps = {
  cols: number;
  rows?: number;
};

// must provide cols based on the table you're putting it in
export const TableLoader = ({ cols, rows = 6 }: TableLoaderProps) => (
  <>
    {Array(rows)
      .fill('')
      .map((_, index0: number) => (
        <tr key={index0} className="animate-pulse">
          {Array(cols)
            .fill('')
            .map((_, index1: number) => (
              <TableCell small sensitive key={index1}>
                <div
                  className="bg-grey-200 h-6 w-12 rounded"
                  style={{ opacity: Number(2 / index1).toFixed(1) }}
                />
              </TableCell>
            ))}
        </tr>
      ))}
  </>
);
