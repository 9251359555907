import { FunctionComponent, PropsWithChildren } from 'react';
import clsx from 'clsx';

type Props = {
  className?: string;
  testId?: string;
};

export const TextLabel: FunctionComponent<PropsWithChildren<Props>> = ({
  children,
  className,
  testId,
}) => (
  <div
    className={clsx('text-grey-500 text-left text-xs', className)}
    data-testid={testId ?? ''}
  >
    {children}
  </div>
);
