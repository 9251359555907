import {
  faCaretDown,
  faCaretUp,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover } from '@headlessui/react';
import clsx from 'clsx';
import { FunctionComponent, useState } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import PatientCard from './PatientCard';

type Props = {
  id: string;
  name: string;
};

export const PatientName: FunctionComponent<Props> = ({ id, name }) => {
  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    strategy: 'absolute',
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 10],
        },
      },
    ],
  });

  let content = <FontAwesomeIcon icon={faSpinner} spin />;

  if (name) {
    content = <span>{name}</span>;
  }

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            className="text-grey-700 focus:ring-grey-300 inline-flex items-center rounded-lg border p-2 focus:outline-none focus:ring-2 focus:ring-offset-2"
            ref={setReferenceElement}
          >
            {content}
            <FontAwesomeIcon
              icon={open ? faCaretUp : faCaretDown}
              className="ml-2"
            />
          </Popover.Button>
          {ReactDOM.createPortal(
            <Popover.Panel
              className={clsx('absolute z-10 rounded-xl border bg-white')}
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}
              unmount
            >
              <PatientCard id={id} />
            </Popover.Panel>,
            document.body,
          )}
        </>
      )}
    </Popover>
  );
};
