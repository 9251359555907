import clsx from 'clsx';
import { FC, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { selectStyles } from '../forms/shared-input-styles';

export type BoolDropdownOption = {
  key: boolean;
  displayValue: string;
};

type BoolDropdownProps = {
  name: string;
  label?: string;
  className?: string;
  options: BoolDropdownOption[];
  disabled?: boolean;
};

const boolToStr = new Map<boolean, string>([
  [true, '1'],
  [false, '0'],
]);

const boolStrToBool = new Map<string, boolean>([
  ['1', true],
  ['0', false],
]);

const BoolDropdown: FC<BoolDropdownProps> = ({
  name,
  label,
  className,
  options,
  disabled,
}) => {
  const { getValues, setValue, getFieldState } = useFormContext();
  const value: string | undefined = boolToStr.get(getValues(name));
  const [selectedValue, setSelectedValue] = useState(
    boolToStr.get(getValues(name)) ?? boolToStr.get(options[0].key),
  );
  const { error } = getFieldState(name);

  const handleChange = (event: { target: { value: string } }): void => {
    setSelectedValue(event.target.value);
    // The value is set manually instead of via registering the field, as
    // the expected value being set should be a bool (not a string).
    setValue(name, boolStrToBool.get(event.target.value));
  };

  useEffect(() => {
    if (value !== undefined) {
      setSelectedValue(value);
    }
  }, [value]);

  return (
    <div>
      {/* TODO: Use ID instead of name */}
      <label htmlFor={name} className="text-grey-500 mb-0 text-sm font-medium">
        {label}
      </label>
      <select
        className={clsx(selectStyles, className)}
        aria-invalid={error ? true : undefined}
        onChange={handleChange}
        value={selectedValue}
        aria-label={name}
        disabled={disabled}
      >
        {options.map(({ key, displayValue }) => {
          const dropdownKey = boolToStr.get(key);

          return (
            <option key={dropdownKey} value={dropdownKey}>
              {displayValue}
            </option>
          );
        })}
      </select>
      <div className="mb-4"></div>
    </div>
  );
};

export default BoolDropdown;
