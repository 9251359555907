import { FunctionComponent, PropsWithChildren } from 'react';
import clsx from 'clsx';

type Props = {
  className?: string;
  variant?: 'medium-xl-grey-900' | 'xl-grey-500' | 'base-grey-700';
  testId?: string;
  id?: string;
  ariaLabel?: string;
};

export const TextData: FunctionComponent<PropsWithChildren<Props>> = ({
  children,
  variant = 'base-grey-700',
  className,
  testId,
  id,
  ariaLabel,
}) => (
  <div
    id={id}
    className={clsx(
      variant === 'medium-xl-grey-900' && 'text-medium text-grey-900 text-xl',
      variant === 'xl-grey-500' && 'text-grey-500 text-xl',
      variant === 'base-grey-700' && 'text-grey-700 text-base',
      className,
    )}
    data-testid={testId ?? ''}
    aria-label={ariaLabel}
  >
    {children}
  </div>
);
