import { useOnMountOnce } from '@pm/core';
import { useSearchParams } from 'react-router-dom';

export type FilterState = URLSearchParams;

export type PushFilterFunction = (key: string, value: string) => void;
export type PopFilterFunction = (key: string, value: string) => void;
export type ResetFilterFunction = () => void;

export type FilterHookReturn = [
  FilterState,
  PushFilterFunction,
  PopFilterFunction,
  ResetFilterFunction,
];

export const useFilters = (defaultFilters?: FilterState): FilterHookReturn => {
  const [searchParams, setSearchParams] = useSearchParams();

  useOnMountOnce(() => {
    if (defaultFilters) {
      defaultFilters.forEach((value, key) => {
        if (!searchParams.get(key)) {
          setSearchParams((params) => {
            params.set(key, value);
            return params;
          });
        }
      });
    }
  });

  const pushFilter: PushFilterFunction = (key: string, value: string) => {
    setSearchParams((params) => {
      const param = params.get(key);
      if (param) {
        if (param.includes(value)) {
          return params;
        }
        params.set(key, [...params.getAll(key), value].join(','));
      } else {
        params.set(key, value);
      }

      return params;
    });
  };

  const popFilter: PopFilterFunction = (key: string, value: string) => {
    const param = searchParams.get(key);
    if (param) {
      const paramArray = param.split(',');
      const index = paramArray.indexOf(value);
      if (index > -1) {
        paramArray.splice(index, 1);
        if (paramArray.length === 0) {
          setSearchParams((params) => {
            params.delete(key);
            return params;
          });
        } else {
          setSearchParams((params) => {
            params.set(key, paramArray.join(','));
            return params;
          });
        }
      }
    }
  };

  const resetFilters: ResetFilterFunction = () => {
    for (const key of searchParams.keys()) {
      setSearchParams((params) => {
        params.delete(key);
        return params;
      });
    }
  };

  return [searchParams, pushFilter, popFilter, resetFilters];
};
