// When we allow patients to edit their pronouns and gender in-app,
// we should retrieve the pronouns from the patient data instead of
// through the questionnaires. When that happens, please delete me
// and forget about me forever.
import { FreddieQuestionnairesFragment } from '@pm/graphql';

/**
 * Retrieves the pronouns from the first Freddie questionnaire.
 *
 * @param {FreddieQuestionnairesFragment[] | null | undefined} questionnaires all questionnaires
 * @returns {string} the patient's pronouns if supplied; otherwise, returns an
 * empty string
 */
export const pronounsFromQuestionnaire = (
  questionnaires: FreddieQuestionnairesFragment[] | null | undefined,
) => {
  if (questionnaires?.length && questionnaires[0].results?.Pronoun) {
    return questionnaires[0].results?.Pronoun ?? '';
  }

  return '';
};

/**
 * Retrieves the gender from the first Freddie questionnaire.
 *
 * @param {FreddieQuestionnairesFragment[] | null | undefined} questionnaires all questionnaires
 * @returns {string} the patient's gender if supplied; otherwise, returns an
 * empty string
 */
export const genderFromQuestionnaire = (
  questionnaires: FreddieQuestionnairesFragment[] | null | undefined,
) => {
  if (questionnaires?.length && questionnaires[0].results?.Gender) {
    return questionnaires[0].results?.Gender ?? '';
  }

  return '';
};
