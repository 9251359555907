import { Dialog, Transition } from '@headlessui/react';
import { Fragment, FunctionComponent, PropsWithChildren } from 'react';
import { useMainAppLayout } from '../../providers/MainAppLayoutProvider';

export const Sidebar: FunctionComponent<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const { sidebarOpen, setSidebarOpen, isDesktop, mediaQueryLoading } =
    useMainAppLayout();

  const showMobileSidebar = !isDesktop && !mediaQueryLoading;
  const showDesktopSidebar = (isDesktop || mediaQueryLoading) && sidebarOpen;

  return (
    <>
      {showMobileSidebar && (
        <Transition.Root show={Boolean(sidebarOpen)} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-10 mt-16 overflow-hidden"
            onClose={setSidebarOpen}
          >
            <div className="absolute inset-0 overflow-hidden">
              {/* darkened overlay */}
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-500"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-500"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="bg-grey-500 absolute inset-0 bg-opacity-80 transition-opacity" />
              </Transition.Child>
              {/* sidebar itself */}
              <div className="fixed inset-y-0 left-0 flex max-w-full">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="-translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="-translate-x-full"
                >
                  <div className="relative w-screen max-w-xs">
                    {/* menu items */}
                    <Dialog.Panel className="bg-menu-background mt-16 flex h-full flex-col overflow-y-auto shadow-xl">
                      <div className="relative flex-1">
                        <nav>
                          <div className="grid grid-cols-1 divide-y">
                            {children}
                          </div>
                        </nav>
                      </div>
                    </Dialog.Panel>
                  </div>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      )}

      <Transition
        show={Boolean(showDesktopSidebar)}
        appear
        className="transition-width transform duration-500 ease-in-out motion-reduce:transition-none"
        enterFrom="w-0"
        enterTo="w-64"
        leaveFrom="w-64"
        leaveTo="w-0"
      >
        <div className="hidden h-full overflow-x-hidden md:flex md:flex-col">
          <div className="bg-menu-background z-10 flex w-64 flex-grow flex-col overflow-y-auto">
            {children}
          </div>
        </div>
      </Transition>
    </>
  );
};
