import { BrandMembership, PatientBrandMembershipFragment } from '@pm/graphql';
import { BrandId } from '../types';

/**
 * Determines whether a user has a membership matching the provided brand
 */
export const hasMembership = (
  memberships: Pick<PatientBrandMembershipFragment, 'brand'>[],
  brandId: BrandId,
) => {
  return memberships.some((membership) =>
    isBrandMember(brandId, membership.brand),
  );
};

export const isBrandMember = (
  brandId: BrandId,
  brand?: Pick<BrandMembership, 'id'> | null,
) => brand?.id === brandId;
