import clsx from 'clsx';
import {
  FunctionComponent,
  ReactNode,
  useEffect,
  useId,
  useState,
} from 'react';
import {
  ControllerRenderProps,
  FieldValues,
  useController,
} from 'react-hook-form';
import { StyledInput } from '../forms/StyledInput';
import { radioStyles } from '../forms/shared-input-styles';

type Props = {
  name: string;
  label: string;
  options: string[] | { text: ReactNode; value: string }[];
  otherField?: boolean;
  otherLabel?: string;
  otherPlaceholder?: string;
  required?: boolean;
  disabled?: boolean;
};

export const RadioToggle: FunctionComponent<Props> = ({
  name,
  label,
  options,
  otherField = true,
  otherLabel = 'Other',
  otherPlaceholder = '',
  required = false,
  disabled = false,
}) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name });
  let _options: { text: string; value: string }[];
  if (typeof options[0] === 'string') {
    _options = (options as string[]).map((option) => ({
      text: option,
      value: option,
    }));
  } else {
    _options = options as { text: string; value: string }[];
  }

  const shouldSelectOther =
    _options.every((option) => option.value !== field.value) &&
    field.value !== '';

  const [otherSelected, setOtherSelected] = useState(shouldSelectOther);
  const [freeTextValue, setFreeTextValue] = useState(
    shouldSelectOther ? field.value : '',
  );
  const id = useId();
  const errorId = `error-${id}`;
  const ariaDescribedBy = error ? errorId : undefined;
  const labelId = `label-${id}`;

  // Default value logic for a pre-filled/partially filled out form
  const isSelected = (value: string) => field.value === value;

  useEffect(() => {
    if (field.value != null) {
      if (shouldSelectOther) {
        setOtherSelected(true);
        setFreeTextValue(field.value);
      }
    }
  }, [field.value, disabled, _options, shouldSelectOther]);

  return (
    <fieldset
      className="space-y-2"
      disabled={disabled}
      aria-labelledby={labelId}
    >
      <legend
        id={labelId}
        className={clsx('text-grey-500 text-sm font-medium', {
          "after:text-critical-dark after:ml-1 after:content-['*']": required,
        })}
      >
        {label}
      </legend>
      <div className="space-y-3">
        {_options.map((option) => (
          <RadioInput
            key={option.value}
            id={`${option.value}-${id}`}
            value={option.value}
            label={option.text}
            aria-describedby={ariaDescribedBy}
            checked={isSelected(option.value)}
            field={field}
            onClick={() => {
              field.onChange(option.value);
              setOtherSelected(false);
              setFreeTextValue('');
            }}
          />
        ))}

        {otherField && (
          <div className="space-y-2">
            <RadioInput
              id={`custom-${id}`}
              label={otherLabel}
              field={field}
              checked={otherSelected}
              onClick={() => {
                field.onChange('');
                setOtherSelected(true);
                setFreeTextValue('');
              }}
            />

            {otherSelected && (
              <div className="ml-6 w-1/2">
                <StyledInput
                  type="text"
                  value={freeTextValue}
                  onChange={(event) => {
                    field.onChange(event.target.value);
                    setFreeTextValue(event.target.value);
                  }}
                  aria-label={otherPlaceholder}
                  aria-describedby={ariaDescribedBy}
                  placeholder={otherPlaceholder}
                />
              </div>
            )}
          </div>
        )}
      </div>
      {error && (
        <p
          className="text-critical-dark mt-2 border-none text-sm font-medium"
          id={errorId}
        >
          {error.message}
        </p>
      )}
    </fieldset>
  );
};

type RadioInputProps = React.HTMLProps<HTMLInputElement> & {
  field?: ControllerRenderProps<FieldValues, string>;
  onClick: () => void;
};

const RadioInput = ({
  id,
  value,
  label,
  field,
  onClick,
  ...props
}: RadioInputProps) => (
  <div className="flex items-center space-x-2">
    <input
      {...field}
      {...props}
      type="radio"
      id={id}
      value={value}
      onClick={onClick}
      className={radioStyles}
    />
    <label htmlFor={id}>{label}</label>
  </div>
);
