import clsx from 'clsx';
import { ClipboardButton } from './ClipboardButton';
import { TextData } from './TextData';
import { TextLabel } from './TextLabel';

export type Props = {
  label?: React.ReactNode;
  copyableText?: string;
  children: React.ReactNode;
  testId?: string;
  variant?: variants;
};

export type variants = 'purposemedBlue' | 'baseGray';

const variantStyles: Record<string, string> = {
  purposemedBlue: 'text-brand-primary-500',
  baseGray: 'text-grey-700',
};

const Clipboard = ({ text, variant }: { text?: string; variant: string }) => {
  if (!text) {
    return null;
  }

  return (
    <ClipboardButton text={text} className={clsx(variantStyles[variant])} />
  );
};

export const TextDataGroup = ({
  label,
  testId,
  copyableText,
  children,
  variant = 'baseGray',
}: Props) => {
  return (
    <div data-testid={testId}>
      <TextLabel {...(testId ? { testId: `${testId}-label` } : {})}>
        {label}
      </TextLabel>
      <TextData
        {...(testId ? { testId: `${testId}-data` } : {})}
        className="flex gap-1"
      >
        {children}
        <Clipboard text={copyableText} variant={variant} />
      </TextData>
    </div>
  );
};
