import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import clsx from 'clsx';

type TooltipProps = TooltipPrimitive.TooltipProps &
  Omit<TooltipPrimitive.TooltipContentProps, 'sideOffset'>;

function Tooltip({
  children,
  content,
  open,
  defaultOpen,
  onOpenChange,
  delayDuration,
  side = 'top',
  align = 'center',
  ...props
}: TooltipProps) {
  return (
    <TooltipPrimitive.Root
      open={open}
      defaultOpen={defaultOpen}
      onOpenChange={onOpenChange}
      delayDuration={delayDuration}
    >
      <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
      <TooltipPrimitive.Portal>
        <TooltipPrimitive.Content
          {...props}
          side={side}
          align={align}
          sideOffset={4}
          className={clsx(
            'bg-grey-900 dark:bg-grey-700 z-50 max-w-xs rounded-lg px-3 py-2 text-sm font-medium text-white shadow-sm transition-opacity duration-300',
            'rdx-side-top:animate-slide-up-fade rdx-side-right:animate-slide-left-fade rdx-side-bottom:animate-slide-down-fade rdx-side-left:animate-slide-right-fade',
          )}
        >
          <TooltipPrimitive.Arrow className="dark:text-grey-800 fill-current text-white" />
          <span className="whitespace-pre-line">{content}</span>
        </TooltipPrimitive.Content>
      </TooltipPrimitive.Portal>
    </TooltipPrimitive.Root>
  );
}

const { TooltipProvider } = TooltipPrimitive;

export { Tooltip, TooltipProvider };
export type { TooltipProps };
