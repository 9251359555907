import { InfoIcon } from '@pm/icons';
import { Tooltip } from './Tooltip';

type InfoIndicatorProps = {
  tooltipContent: string;
};

export const InfoIndicator = ({ tooltipContent }: InfoIndicatorProps) => {
  return (
    <div className="my-auto flex text-gray-400">
      <Tooltip content={tooltipContent}>
        <button type="button">
          <InfoIcon />
        </button>
      </Tooltip>
    </div>
  );
};
