import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  GetFreddiePatientSummaryQuery,
  PrescriptionFragment,
  useGetFreddiePatientSummaryQuery,
} from '@pm/graphql';
import { FunctionComponent } from 'react';
import {
  getLatestPrepPrescription,
  getNumPillsRemainingInPrescription,
} from '../../utilities/prescriptionUtilities';
import { ButtonLink } from '../ButtonLink';
import { ClipboardButton } from '../ClipboardButton';
import { DateOfBirthDisplay } from '../DateOfBirthDisplay/DateOfBirthDisplay';
import { HealthCardNumber } from '../HealthCardNumber';
import { PhoneNumberDisplay } from '../PhoneNumberDisplay/PhoneNumberDisplay';
import { TextData } from '../TextData';
import { TextLabel } from '../TextLabel';

type Props = {
  id: string;
};

const NOT_PROVIDED_TEXT = '(Not provided)';

const PatientCard: FunctionComponent<Props> = ({ id }) => {
  const { data: patient } = useGetFreddiePatientSummaryQuery({
    variables: { id },
  });

  if (!patient || !patient.user) {
    return (
      <div className="p-10">
        <FontAwesomeIcon icon={faSpinner} spin />
      </div>
    );
  }

  return <PatientCardImpl user={patient.user} />;
};

type PatientCardImplProps = {
  user: NonNullable<GetFreddiePatientSummaryQuery['user']>;
};

export const PatientCardImpl = ({ user }: PatientCardImplProps) => {
  const latestPrepPrescription = user.prescriptions
    ? getLatestPrepPrescription(
        user.prescriptions.nodes as PrescriptionFragment[],
      )
    : undefined;

  return (
    <div className="space-y-2 px-4 py-6">
      <div>
        <TextData variant="medium-xl-grey-900" className="mb-4">
          {user.firstName} {user.lastName}
        </TextData>
        <div className="grid grid-flow-col grid-rows-3 gap-x-4">
          <div className="mb-1" data-testid="dob">
            <TextLabel>DOB</TextLabel>
            <TextData>
              <DateOfBirthDisplay
                dateOfBirth={user.profile?.dateOfBirth}
                fallbackMessage={NOT_PROVIDED_TEXT}
              />
            </TextData>
          </div>
          <div className="mb-1" data-testid="health-care-number">
            <TextLabel>Health card number</TextLabel>
            <TextData>
              <HealthCardNumber
                healthCardNumber={user.profile?.healthCareNumber}
                provinceOfCoverageId={user.profile?.healthCareProvince?.id}
                notProvidedText={NOT_PROVIDED_TEXT}
              />
            </TextData>
          </div>
          <div data-testid="health-care-province">
            <TextLabel>Province of coverage</TextLabel>
            <TextData>
              {user.profile?.healthCareProvince?.name || NOT_PROVIDED_TEXT}
            </TextData>
          </div>
          <div className="mb-1" data-testid="phone">
            <TextLabel>Phone number</TextLabel>
            <TextData>
              <PhoneNumberDisplay
                phone={user.phone}
                notProvidedMessage={NOT_PROVIDED_TEXT}
              />
            </TextData>
          </div>
          <div className="mb-1" data-testid="email">
            <TextLabel>Email address</TextLabel>
            <TextData className="space-x-2">
              <span>{user.email}</span>
              {user.email && <ClipboardButton text={user.email} />}
            </TextData>
          </div>
          {latestPrepPrescription && (
            <div data-testid="pill-counter">
              <TextLabel>Pill counter</TextLabel>
              <TextData>
                {
                  getNumPillsRemainingInPrescription(latestPrepPrescription)
                    .numPillsRemaining
                }
              </TextData>
            </div>
          )}
        </div>
      </div>
      <div className="flex gap-4">
        <ButtonLink
          to={`/patients/${user.id}`}
          variant="secondary"
          size="small"
        >
          View
        </ButtonLink>
        <ButtonLink
          to={`/conversations/new?toId=${user.id}`}
          variant="secondary"
          size="small"
        >
          Message
        </ButtonLink>
      </div>
    </div>
  );
};

export default PatientCard;
