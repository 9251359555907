import { ReactNode } from 'react';

type WrapperVariant = 'stacked' | 'inline';

type InputWrapperProps = {
  variant?: WrapperVariant;
  children: ReactNode;
};

const temporaryWrapperStyles: Record<WrapperVariant, string> = {
  stacked: 'flex flex-col gap-2',
  inline: 'flex items-center space-x-2',
};

/* Intended to use for consistent spacing for inputs */
export const InputWrapper = ({
  variant = 'stacked',
  children,
}: InputWrapperProps) => (
  <div className={temporaryWrapperStyles[variant]}>{children}</div>
);
