import { FunctionComponent } from 'react';
import { PatientQueryUserFragment } from '@pm/graphql';

type Props = {
  patient: PatientQueryUserFragment;
};

export const ProvinceCode: FunctionComponent<Props> = ({ patient }) => {
  const province = patient.profile?.province;
  return <>{province?.code}</>;
};
