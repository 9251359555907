import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog } from '../Dialog';
import { ButtonLink } from '../ButtonLink';

type Props = {
  open: boolean;
  onClose: () => void;
};

export const ConfirmationDialog: FunctionComponent<Props> = ({
  open,
  onClose,
}) => {
  const { t } = useTranslation('payment-methods', {
    keyPrefix: 'Stripe.ConfirmationDialog',
  });

  return (
    <Dialog open={open} onClose={onClose} className="mx-4">
      <div className="space-y-5">
        <div className="text-grey-900 text-lg font-medium">{t('Title')}</div>
        <ButtonLink to="/" variant="secondary" onClick={onClose}>
          {t('Button')}
        </ButtonLink>
      </div>
    </Dialog>
  );
};
