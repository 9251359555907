import { type ReactNode } from 'react';
import { type VariantProps, cva } from 'class-variance-authority';

const sidePanelCTA = cva('flex flex-row justify-end gap-m p-xl');

type SidePanelCtaProps = VariantProps<typeof sidePanelCTA> & {
  children: ReactNode;
};

/**
 * Wrapper for the (optional) CTA section of SidePanel.
 */
export const Cta = ({ children }: SidePanelCtaProps) => {
  return <div className={sidePanelCTA()}>{children}</div>;
};
