import { isAfter, isBefore } from 'date-fns';
import { useInterval } from './use-interval';
import { useForceUpdate } from '../use-force-update/use-force-update';
import { ms } from '../../utilities/milliseconds';

export const useForceUpdatePeriod = (
  startDate: Date,
  endDate: Date,
  delayInMs = ms.minutes(1),
) => {
  const forceRerender = useForceUpdate();
  const now = new Date();
  const withinRange = isAfter(now, startDate) && isBefore(now, endDate);

  useInterval(forceRerender, withinRange ? delayInMs : null);

  return withinRange;
};
