import * as Menubar from '@radix-ui/react-menubar';
import { VariantProps, cva } from 'class-variance-authority';

export const OverflowMenu = (props: Menubar.MenubarProps) => {
  return (
    <Menubar.Root>
      <Menubar.Menu {...props} />
    </Menubar.Root>
  );
};

const overflowMenuContent = cva(
  'relative bg-surface-strong min-w-[240px] max-h-[266px] overflow-y-auto rounded-s shadow-l',
  {
    variants: {
      padding: {
        default: 'p-xxs',
        none: 'p-none',
      },
    },
    defaultVariants: {
      padding: 'default',
    },
  },
);

const OverflowMenuContent = (
  props: Menubar.MenubarContentProps & VariantProps<typeof overflowMenuContent>,
) => {
  return (
    <Menubar.Portal>
      <Menubar.Content
        aria-label="Overflow Menu Content"
        className={overflowMenuContent({ padding: props.padding })}
        sideOffset={8}
        {...props}
      />
    </Menubar.Portal>
  );
};

const OverflowMenuItem = (props: Menubar.MenubarItemProps) => {
  return (
    <Menubar.Item
      aria-label={props['aria-label']}
      className="font-normal px-m py-s rounded-xs data-[highlighted]:bg-hover-opacity active:bg-active-opacity hover:cursor-pointer focus-visible:outline-none"
      {...props}
    />
  );
};

const OverflowMenuTrigger = (props: Menubar.MenubarTriggerProps) => {
  return <Menubar.Trigger asChild aria-label="Open Overflow Menu" {...props} />;
};

OverflowMenu.Content = OverflowMenuContent;
OverflowMenu.Item = OverflowMenuItem;
OverflowMenu.Trigger = OverflowMenuTrigger;
OverflowMenu.Separator = Menubar.Separator;
