import { FunctionComponent, PropsWithChildren } from 'react';
import { useFormikContext } from 'formik';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, type ButtonProps } from '../Button';

type Props = Pick<ButtonProps, 'variant' | 'className' | 'disabled'>;

export const SubmitButton: FunctionComponent<PropsWithChildren<Props>> = ({
  disabled,
  className,
  variant,
  children,
}) => {
  const { isSubmitting, isValid } = useFormikContext();
  const isDisabled = isSubmitting || !isValid || disabled;

  return (
    <Button
      type="submit"
      className={className}
      title={!isValid ? 'Please fix the errors shown above' : undefined}
      data-cy="submit"
      data-testid="submit"
      disabled={isDisabled}
      variant={variant}
    >
      {isSubmitting ? (
        <FontAwesomeIcon icon={faCircleNotch} className="h-5 w-5" spin />
      ) : (
        children
      )}
    </Button>
  );
};
