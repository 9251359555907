import { WorkflowStepBasicDataFragment } from '@pm/graphql';
import { compareDesc, parseISO } from 'date-fns';

/**
 * Gets the most recently completed workflow step from an array of steps
 * Incomplete steps are sorted to the end of the array
 */
export const getLatestCompletedWorkflowStep = (
  steps: WorkflowStepBasicDataFragment[] | [] | undefined,
) => {
  if (steps && steps.length > 0) {
    const mostRecentToLeastRecentSteps = [...steps].sort(
      (firstStep, secondStep) => {
        const compared = compareDesc(
          parseISO(firstStep.completedAt || '1970-01-01T00:00:00Z'),
          parseISO(secondStep.completedAt || '1970-01-01T00:00:00Z'),
        );
        return compared;
      },
    );

    return {
      stepId: mostRecentToLeastRecentSteps[0].id,
      stepCompletionParams: mostRecentToLeastRecentSteps[0].completionParams,
    };
  }
  return { stepId: null, stepCompletionParams: null };
};

/**
 * Intended for use with the Frida screening questionnaire
 * Pulls the boolean value from the completion params for diagnosis only
 * Defaults to false
 */
export const getDiagnosisOnlyStatus = (
  completionParams: Record<string, string | boolean | null>,
) => {
  return completionParams?.diagnosisOnly ? true : false;
};
