import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import InputDatePicker, { InputDatePickerProps } from './InputDatePicker';

interface HookFormInputDatePickerProps extends InputDatePickerProps {
  name: string;
}

export const HookFormInputDatePicker = (
  props: HookFormInputDatePickerProps,
) => {
  const { formState, trigger, setValue } = useFormContext();
  const { errors } = formState;

  return (
    <div className="space-y-2">
      <InputDatePicker
        {...props}
        onChange={(value: Date | null) => {
          setValue(props.name, value);
          if (value) trigger(props.name);
        }}
        onBlur={(e) => {
          props.onBlur?.(e);
          trigger(props.name);
        }}
        error={errors[props.name]?.message as string}
      />
      <ErrorMessage
        name={props.name}
        errors={errors}
        as="p"
        className="text-critical-dark text-sm"
      />
    </div>
  );
};

export default HookFormInputDatePicker;
