import { TextData } from './TextData';
import { TextLabel } from './TextLabel';

type Props = {
  patientId: React.ReactNode;
  preferredName?: string | null;
  firstName?: React.ReactNode;
  lastName?: React.ReactNode;
  pronouns?: string;
  notProvidedText: string;
  testId?: string;
};

type NameProps = {
  legal: React.ReactNode;
  preferred?: string | null;
  testId?: string;
};

const Name = ({ preferred, legal, testId }: NameProps) => (
  <div className="flex flex-row space-x-1">
    {preferred && (
      <TextData variant="medium-xl-grey-900">{preferred + ' - '}</TextData>
    )}
    <TextData variant="xl-grey-500" testId={testId && `${testId}-patient-name`}>
      {legal}
    </TextData>
  </div>
);

export const PatientVocative = ({
  testId,
  patientId,
  preferredName,
  firstName,
  lastName,
  pronouns,
  notProvidedText,
}: Props) => {
  const legalName = [firstName, lastName]
    .filter((value) => value)
    .join(' ') || <i>{notProvidedText}</i>;

  return (
    <div className="mb-4">
      <TextLabel>{patientId}</TextLabel>
      <Name preferred={preferredName} legal={legalName} testId={testId} />
      <div>
        {pronouns && (
          <TextLabel
            className="mt-1"
            testId={testId && `${testId}-header-pronouns`}
          >{`(${pronouns})`}</TextLabel>
        )}
      </div>
    </div>
  );
};
