import { FunctionComponent, useEffect, useState } from 'react';
import { faTimes, faFile } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormikContext } from 'formik';
import clsx from 'clsx';
import { Attachment } from '../Attachments';
import { useEnvironment } from '../../hooks/env';
import { Uploader } from './Uploader';

type Props = {
  attachment: Attachment;
  onRemove: () => void;
  error?: string;
};

export const UploadAttachment: FunctionComponent<Props> = ({
  attachment,
  onRemove,
  error,
}) => {
  const [progress, setProgress] = useState(0);
  const { validateField } = useFormikContext();
  const { directUploadsUrl } = useEnvironment();

  useEffect(() => {
    const uploader = new Uploader(attachment, directUploadsUrl, setProgress);
    uploader.upload((blob) => {
      attachment.blob = blob;
      validateField('attachments');
    });
  }, [attachment, validateField, directUploadsUrl]);

  return (
    <li
      className={clsx(
        'col-span-1 flex rounded-md bg-white shadow-sm',
        error && 'overflow-hidden ring-1 ring-red-600',
      )}
    >
      <div className="flex w-16 flex-shrink-0 items-center justify-center rounded-l-md bg-pink-600 text-sm font-medium leading-5 text-white">
        <FontAwesomeIcon icon={faFile} />
      </div>
      <div className="grid flex-1">
        <div
          className="bg-grey-200 col-start-1 row-start-1 h-full"
          style={{ width: `${progress * 100}%` }}
        ></div>
        <div className="col-start-1 row-start-1 flex overflow-hidden">
          <div title={attachment.name} className="flex-1 truncate p-4">
            {attachment.name}
          </div>
          <div className="ml-auto">
            <button
              disabled={!attachment.blob}
              type="button"
              className="text-grey-400 hover:text-grey-500 focus:bg-grey-100 focus:text-grey-500 inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent transition focus:outline-none"
              onClick={onRemove}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
        </div>
      </div>
    </li>
  );
};
